@font-face {
font-family: 'yahooSans';
src: url(/_next/static/media/7553561d3efb1daf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200 900;
}@font-face {font-family: 'yahooSans Fallback';src: local("Arial");ascent-override: 94.56%;descent-override: 23.94%;line-gap-override: 0.00%;size-adjust: 100.68%
}.__className_288502 {font-family: 'yahooSans', 'yahooSans Fallback'
}.__variable_288502 {--font-yahoo-sans: 'yahooSans', 'yahooSans Fallback'
}

@font-face {
font-family: 'centra';
src: url(/_next/static/media/f0b1e3f007006b62-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'centra';
src: url(/_next/static/media/f35b5e6220b536eb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'centra';
src: url(/_next/static/media/6563b172ef52338e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: 'centra Fallback';src: local("Arial");ascent-override: 95.65%;descent-override: 28.69%;line-gap-override: 0.00%;size-adjust: 104.55%
}.__className_05dfd4 {font-family: 'centra', 'centra Fallback'
}.__variable_05dfd4 {--font-centra: 'centra', 'centra Fallback'
}

